@import '../../../css/variables';

.bl-lees-meer {
  $block: &;

  &__titel {
    @include h3;
    width: 150%;
    position: relative;
    z-index: 1;
    color: $color-secondary;

    @media (max-width: 768px){
      width: 100%;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    background: $color-primary;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: calc(10 / 16 * 100%);
    }

    @media(max-width: 768px){
      margin-top: 2rem;
    }
  }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //opacity: 0.5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $color-primary;
    //background-blend-mode: luminosity;
  }

  &__button-container {
    margin-top: 2rem;
  }

  &__button {
    @include button(#fff, $color-secondary);
  }

  &__devider {
    border: none;
    border-bottom: 2px solid $color-secondary;
    margin: 3rem 0;
  }


  &--reverse {
    .row {
      flex-direction: row-reverse;
    }

    #{$block}__titel {
      margin-left: -50%;
      text-align: right;

      @media(max-width: 768px){
        margin: 0;
        text-align: left;
      }
    }

    #{$block}__button-container {
      text-align: right;
    }
  }

  &--margin {
    margin-bottom: 3rem;
  }

}